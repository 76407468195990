.services-section {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;
  padding: 1rem;
}

/* Style for individual service offered */
.services-offered {
  flex: 0 1 calc(50% - 1rem); /* Take 50% of the row minus the gap */
  border: 1px solid #eee;
  padding: 1rem;
  border-radius: 8px;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
}

/* Darken the background color when hovering */
.services-offered:hover {
  transform: scale(1.005);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
  background-color: #e0e0e0; /* Darker background */
}

/* Add spacing and proper alignment for the checkbox and label */
.services-offered label {
  display: flex;
  align-items: center;
  gap: 0.8rem; /* Create some space between checkbox and service name */
  cursor: pointer;
  font-size: 1rem;
  color: #333;
  transition: color 0.3s;
}

/* Hover effect for service labels */
.services-offered label:hover {
  color: #010e01;
}

/* Styling for the checkbox itself */
.services-offered input[type="checkbox"] {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  accent-color: #0073e6; /* Custom accent color for checkbox */
  border-radius: 4px; /* Rounded edges for checkboxes */
  transition: transform 0.2s ease-in-out;
}

/* Add hover effect for the checkbox */
.services-offered input[type="checkbox"]:hover {
  transform: scale(1.1);
}

/* Styling for the active service's product section */
.products-section {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;
  padding: 1rem;
  background: #f9f9f9;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
}

/* Style for the product list */
.products-section ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.products-section li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid #ddd;
}

.products-section li:last-child {
  border-bottom: none;
}

.products-section label {
  font-size: 1rem;
  color: #555;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: color 0.3s;
}

/* Hover effect for product labels */
.products-section label:hover {
  color: #011102;
}

/* Price Input Styling */
.products-section input[type="number"] {
  width: 80px;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.3s, box-shadow 0.3s;
}

/* Price Input Focus Styling */
.products-section input[type="number"]:focus {
  outline: none;
  border-color: #010e01;
  box-shadow: 0 0 5px rgba(2, 5, 2, 0.3);
}

/* Button hover effects */
.centered-submit-btn {
  background-color: #0073e6;
  color: white;
  border-radius: 6px;
  border: none;
  padding: 0.8rem 1.2rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  width: auto;
  margin: 0 auto;
  display: block;
}

/* Hover effect for submit button */
.centered-submit-btn:hover {
  background-color: #005bb5;
  box-shadow: 0 6px 16px rgba(0, 91, 181, 0.3);
}

.submit-btn-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
}

/* Error message styling */
.error {
  color: #d32f2f;
  font-size: 1rem;
  text-align: center;
  margin-bottom: 1rem;
}

/* Responsive styles */
@media (max-width: 768px) {
  .services-offered {
    flex: 0 1 calc(100% - 1rem); /* Full width for smaller screens */
  }

  .services-section {
    gap: 1rem; /* Adjust the gap for smaller screens */
    padding: 0.5rem;
  }

  .products-section input[type="number"] {
    width: 100%; /* Make inputs full-width */
  }

  .centered-submit-btn {
    font-size: 0.9rem;
    padding: 0.7rem 1rem;
  }
}

@media (max-width: 480px) {
  .services-offered {
    padding: 0.8rem; /* Smaller padding for very small screens */
  }

  .services-section {
    padding: 0.5rem;
  }

  .centered-submit-btn {
    font-size: 0.8rem;
    padding: 0.6rem 0.8rem;
  }
}



.services-section {
  flex-direction: column; 
}

.services-offered, .add-address {
  flex: 1 1 100%; 
  margin-bottom: 1rem; 
}
