.rakesh-tab {
  display: block;
  width: 120px;
  height: 25px;
  line-height: 25px;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  margin: 0px auto auto auto;
  background: #fff;
  font-size: 12px;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
}

.rakesh-tab:after {
  content: "";
  position: absolute;
  border: 25px solid transparent;
  border-top-color: #fff;
  top: 0px;
  right: -28px;
}

.rakesh-tab:before {
  content: "";
  position: absolute;
  border: 25px solid transparent;
  border-top-color: #fff;
  top: 0px;
  left: -28px;
}

.get-quotes a {
  background-color: #ffffff;
}
.get-quotes a:hover {
  border-color: #ffffff;
  background-color: #2196f3;
}
.background-box-1 {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("../img/blog-1.jpg"), no-repeat center center;
}

.background-box-2 {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("../img/blog-2.jpg"), no-repeat center center;
}

.background-box-3 {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("../img/blog-3.jpg"), no-repeat center center;
}
.white-title {
  color: #ffffff;
}

.position-relative .plan-title {
  text-align: left;
  padding: 0px 20px;
}
