.dashboard-container {
  display: flex;
  height: 100vh;
  font-family: "Roboto", sans-serif;
  position: relative;
}

.left-sidebar {
  flex: 0.15;
  background-color: #eef0f5; 
  padding: 1rem;
  border-right: 2px solid #e0e7ff; 
  transition: transform 0.3s ease-in-out;
}

.left-sidebar.hidden {
  transform: translateX(-100%);
}

.left-sidebar.visible {
  transform: translateX(0);
}

.left-sidebar h2 {
  margin-bottom: 1rem;
  color: #003f88; 
  font-size: 1.5rem;
  font-weight: bold;
}

.left-sidebar ul {
  list-style: none;
  padding: 0;
}

.left-sidebar ul li {
  padding: 10px;
  display: flex;
  margin-bottom: 0.5rem;
  /* background: #9fcced;  */
  color: #004aad; 
  border-radius: 8px;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); 
  transition: transform 0.2s ease, box-shadow 0.2s ease, background-color 0.2s ease;
}

.left-sidebar ul li .icon {
  margin-right: 10px;
  font-size: 20px;
  color: #0078d4; 
}

.left-sidebar ul li:hover {
  background-color: #d0e8ff; 
  color: #002d62; 
  transform: translateX(5px); 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
}

.profile-dash {
  margin-top: auto;
  text-align: left;
  padding: 1rem;
}

/* .left-sidebar ul li:last-child {
  background-color: #ffeded; 
  color: #ff4d4d; 
  font-weight: bold;
} */
/* 
.left-sidebar ul li:last-child:hover {
  background-color: #ffe2e2; 
  color: #c0392b; 
  transform: translateX(5px);
  box-shadow: 0 4px 8px rgba(255, 0, 0, 0.2); 
} */

.toggle-button {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #004aad; 
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 18px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.toggle-button:hover {
  background-color: #003f88; 
}

.left-sidebar ul li.active-tab {
  background-color: #1a82eb; 
  color: white; 
  font-weight: bold; 
}

.left-sidebar ul li.active-tab:hover {
  background-color: #2c6cad; 
}


.right-content {
  flex: 0.85;
  padding: 1rem;
  overflow-y: auto;
}

.toggle-button {
  display: none;
  position: absolute;
  top: 1rem;
  left: 1rem;
  background-color: #0073e6;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  z-index: 10;
}

.toggle-button:hover {
  background-color: #005bb5;
}

.tab-content {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

h3 {
  margin-bottom: 1rem;
  color: #333;
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
  .vendor-services-list {
    grid-template-columns: repeat(3, 1fr); /* 3 cards on tablets */
  }
}

@media (max-width: 768px) {
  .vendor-services-list {
    grid-template-columns: repeat(2, 1fr); /* 2 cards on smaller screens */
  }
}

@media (max-width: 480px) {
  .vendor-services-list {
    grid-template-columns: 1fr; /* 1 card per row on very small screens */
  }
}

.user-profile {
  position: absolute;
  bottom: 20px;
  left: 0px;
  color: black;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.user-profile img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
