/*! CSS Used from: https://bestjquery.com/tutorial/css/bootstrap-grid.min.css */
a {
  background-color: transparent;
}
a:active,
a:hover {
  outline: 0;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
a {
  color: #337ab7;
  text-decoration: none;
}
a:hover,
a:focus {
  color: #23527c;
  text-decoration: underline;
}
a:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
h1,
h3 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}
h1,
h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}
h1 {
  font-size: 36px;
}
h3 {
  font-size: 24px;
}
.text-center {
  text-align: center;
}
ul {
  margin-top: 0;
  margin-bottom: 10px;
}
.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
.row {
  margin-left: -15px;
  margin-right: -15px;
}
.col-md-4,
.col-sm-6 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 768px) {
  .col-sm-6 {
    float: left;
  }
  .col-sm-6 {
    width: 50%;
  }
}
@media (min-width: 992px) {
  .col-md-4 {
    float: left;
  }
  .col-md-4 {
    width: 33.33333333%;
  }
}
.container:before,
.container:after,
.row:before,
.row:after {
  content: " ";
  display: table;
}
.container:after,
.row:after {
  clear: both;
}
/*! CSS Used from: https://bestjquery.com/tutorial/css/common-1.css */
a:hover,
a:focus {
  text-decoration: none;
  outline: none;
}
.plan-box {
  padding: 0px 0 20px;
}
h1.white {
  color: #000;
  font-size: 20px;
  font-weight: bold;
  text-transform: capitalize;
  margin: 0;
  padding-bottom: 30px;
}
@media only screen and (max-width: 767px) {
  h1.white {
    font-size: 18px;
  }
}
/*! CSS Used from: https://bestjquery.com/tutorial/pricing-table/demo91/css/style.css */
/* .demo{background:#E9E8E6;} */
.pricingTable {
  /* text-align: center; */
  margin: 0 10px;
  position: relative;
  z-index: 1;
}
.pricingTable .pricingTable-header {
  color: #fff;
  /* background-color: #2196f3; */
  background-image: linear-gradient(to right bottom, #cbec82, #7fe8a9, #38dcd1, #3acae9, #75b4e6);
  text-align: center;
  padding: 40px 0 30px;
  /* margin:0 10px; */
  /* height: 170px; */
  display: block;
  -webkit-clip-path: polygon(50% 0%, 100% 38%, 100% 100%, 0 100%, 0% 38%);
  clip-path: polygon(50% 0%, 100% 38%, 100% 100%, 0 100%, 0% 38%);
  position: relative;
  z-index: -1;
}
.pricingTable .title {
  font-size: 28px;
  margin: 10px 0px 10px 0px;
}
.pricingTable .pricing-content {
  background-image: linear-gradient(to right bottom, #cbec82, #7fe8a9, #38dcd1, #3acae9, #75b4e6);
  border-radius: 20px;
  padding: 15px 1px 5px;
  margin-top: -15px;
  z-index: 2;
  transition: all 0.3s;
}

.pricingTable:hover .pricing-content {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
}
.pricingTable .content {
  background: #fff;
  border-radius: 10px;
  padding: 10px 10px 42px;
  margin-bottom: 20px;
  clip-path: polygon(50% 100%, 100% 80%, 100% 0, 0 0, 0 80%);
}
.pricingTable .content ul {
  list-style: disc;
  color: #000;
  text-align: left;
  font-size: 18px;
  line-height: 30px;
  text-transform: capitalize;
  /* letter-spacing: 1px; */
  padding: 0;
  display: inline-block;
  margin: 0 auto 5px;
}
.pricingTable .content ul {
  width: 100%;
}
.pricingTable .content ul li span {
  color: #2196f3;
}
.pricingTable .price-value {
  color: #000;
  font-size: 30px;
  transition: all 0.3s;
}
.pricingTable .amount {
  color: #2196f3;
}
.pricingTable .pricingTable-signup {
  font-size: 20px;
  text-transform: uppercase;
  position: relative;
}
/* .pricingTable .pricingTable-signup:hover{padding:10px 15px;box-shadow:0 0 20px rgba(255,255,255,0.7) inset;} */
.pricingTable.green .pricingTable-header {
  color: #6cb022;
}
.pricingTable.green .price-value .amount {
  color: #6cb022;
}
.pricingTable.green .pricing-content ul span {
  color: #6cb022;
}
.pricingTable.green .pricing-content {
  background: linear-gradient(to bottom, #b0c333, #46a450);
}
.pricingTable.blue .pricingTable-header {
  color: #1ca2d8;
}
.pricingTable.blue .price-value .amount {
  color: #1ca2d8;
}
.pricingTable.blue .pricing-content ul span {
  color: #1ca2d8;
}
.pricingTable.blue .pricing-content {
  background: linear-gradient(to bottom, #b2dac2, #51b9b6);
}
@media only screen and (max-width: 990px) {
  .pricingTable {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 479px) {
  .pricingTable {
    margin: 0 0 30px;
  }
  .pricingTable .content ul {
    font-size: 15px;
  }
  .pricingTable .price-value {
    font-size: 23px;
  }
}

@media (max-width: 767px) {
  .ribbon {
    font-size: 14px !important;
    text-align: center;
  }
  .dv-booking {
    padding: 0px !important;
  }
  .dv-offer {
    padding: 0px !important;
  }
  .pricingTable .title {
    font-size: 18px;
  }

  .description-mobile {
    display: block !important;
    position: relative;
  }
  .description-desktop{
    display: none !important;
  }
}

/*! CSS Used fontfaces */
/* @font-face{font-family:'Roboto Condensed';font-style:normal;font-weight:400;src:local('Roboto Condensed'), local('RobotoCondensed-Regular'), url(https://fonts.gstatic.com/s/robotocondensed/v18/ieVl2ZhZI2eCN5jzbjEETS9weq8-19-7DRs5.woff2) format('woff2');unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:'Roboto Condensed';font-style:normal;font-weight:400;src:local('Roboto Condensed'), local('RobotoCondensed-Regular'), url(https://fonts.gstatic.com/s/robotocondensed/v18/ieVl2ZhZI2eCN5jzbjEETS9weq8-19a7DRs5.woff2) format('woff2');unicode-range:U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:'Roboto Condensed';font-style:normal;font-weight:400;src:local('Roboto Condensed'), local('RobotoCondensed-Regular'), url(https://fonts.gstatic.com/s/robotocondensed/v18/ieVl2ZhZI2eCN5jzbjEETS9weq8-1967DRs5.woff2) format('woff2');unicode-range:U+1F00-1FFF;}
@font-face{font-family:'Roboto Condensed';font-style:normal;font-weight:400;src:local('Roboto Condensed'), local('RobotoCondensed-Regular'), url(https://fonts.gstatic.com/s/robotocondensed/v18/ieVl2ZhZI2eCN5jzbjEETS9weq8-19G7DRs5.woff2) format('woff2');unicode-range:U+0370-03FF;}
@font-face{font-family:'Roboto Condensed';font-style:normal;font-weight:400;src:local('Roboto Condensed'), local('RobotoCondensed-Regular'), url(https://fonts.gstatic.com/s/robotocondensed/v18/ieVl2ZhZI2eCN5jzbjEETS9weq8-1927DRs5.woff2) format('woff2');unicode-range:U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:'Roboto Condensed';font-style:normal;font-weight:400;src:local('Roboto Condensed'), local('RobotoCondensed-Regular'), url(https://fonts.gstatic.com/s/robotocondensed/v18/ieVl2ZhZI2eCN5jzbjEETS9weq8-19y7DRs5.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Roboto Condensed';font-style:normal;font-weight:400;src:local('Roboto Condensed'), local('RobotoCondensed-Regular'), url(https://fonts.gstatic.com/s/robotocondensed/v18/ieVl2ZhZI2eCN5jzbjEETS9weq8-19K7DQ.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;} */

.dv-offer {
  position: fixed;
  right: 0;
  top: 53%;
  background-color: #2196f3;
  padding: 10px;
  border-radius: 20px 0px 0px 20px;
  transition: width 0.2s;
  transition-timing-function: linear;
  overflow: hidden;
  z-index: 5;
}

.dv-booking {
  position: fixed;
  right: 0;
  /* top: 49%; */
  background-color: #2196f3;
  padding: 10px;
  border-radius: 20px 0px 0px 20px;
  transition: width 0.2s;
  transition-timing-function: linear;
  overflow: hidden;
  z-index: 5;
}

.dv-booking-content {
  display: none;
  vertical-align: middle;
  font-size: 18px;
}

.dv-booking-whatup {
  display: none;
  vertical-align: middle;
  font-size: 18px;
}
/* 
.dv-booking:hover {
    display: block;
    background-color: #e7e7e7ccc; 
} */

.dv-icon {
  display: inline-block;
  padding: 3px 2px 7px 8px;
}

.cart-totals {
	border-radius: 3px;
	background: #e7e7e7;
	padding: 25px;
}
.cart-totals h3 {
	font-size: 19px;
    color: #3c3c3c;
    letter-spacing: 1px;
    font-weight: 500;
}
.cart-totals table {
	width: 100%;
}
.cart-totals table tr th,
.cart-totals table tr td {
	width: 50%;
    padding: 3px 0;
    vertical-align: middle;
}
.cart-totals table tr td:last-child {
	text-align: right;
}
.cart-totals table tr td.subtotal {
	font-size: 20px;
    color: #6f6f6f;
}
.cart-totals table tr td.free-shipping {
	font-size: 14px;
    color: #6f6f6f;
}
.cart-totals table tr.total-row td {
	padding-top: 25px;
}
.cart-totals table tr td.price-total {   
	font-size: 24px;
    font-weight: 600;
    color: #8660e9;
}
.btn-cart-totals {
	text-align: center;
	margin-top: 60px;
	margin-bottom: 20px;
}
.btn-cart-totals .round-black-btn {
	margin: 10px 0; 
}

.container-tab {
  border-top: 1px solid #e7e7e7
}

.tab-list {
  height: 50px;
  display: flex;
  list-style: none;
  font-size: 18px;
  padding: 0;
  margin: 0;
}

.tabs {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  position: relative;
  cursor: pointer;
}

.tabs:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.3);
}

.active-tabs {
  background-color: #2196F3;
  color: #fff;
}

.active-tabs::before {
  content: "";
  display: block;
  position: absolute;
  top: -4px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 2px);
  height: 4px;
}

.tab-content-title {
    /* background-color: #eeeded; */
    display: none;
    padding: 10px;
}

.tab-content-title-box {
  background-color: #fff;
  display: none;
  padding: 10px;
  color: #2196F3;
}


.active-content {
  display: flex;
  border-top: 1px solid #e7e7e7;
} 



.left-tab-container {
  display: flex;
  flex-direction: row;
}

.left-tab-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end; /* Align tabs to the right */
}

.left-tab {
  padding: 10px;
  cursor: pointer;
  border: 1px solid #e7e7e7;
  border-bottom: none;
  border-radius: 5px 5px 0 0;
  background-color: #fff;
  /* Fixed width for left tabs */
  /* width: 200px;  */
  text-align: center; /* Center align the text within the button */
}

.left-tab.active {
  background-color: #2196F3;
  color: #fff;
}

.left-tab-content {
  padding: 20px;
  border: 1px solid #e7e7e7;
  border-radius: 0 5px 5px 5px;
  margin-left: -1px; /* To align with the tab borders */
}

.description-mobile{
  display: none;
}
.description-desktop{
  display: block;
}
.price-value-box{
  /* top: -14px; */
  position: relative;
}