.uniform-width {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  box-sizing: border-box;
}

.custom-quill-editor .ql-container {
  height: 200px; 
}
