form input {
    width: 100%;
    padding: 0.75rem;
    margin-bottom: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  form button {
    background-color: #0073e6;
    color: #fff;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
  }
  
  form button:hover {
    background-color: #005bb5;
  }
  
  .account-details {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    background: #f7f9fc;
    border: 1px solid #d1d9e6;
    border-radius: 8px;
  }
  
  .account-details p {
    font-size: 1rem;
    color: #444;
    margin: 0;
  }
  
  .account-details p strong {
    font-weight: 600;
    color: #0066cc;
  }
  
  .account-details button {
    align-self: flex-start;
    background: #0066cc;
    color: #fff;
    padding: 0.4rem 0.8rem;
    border: none;
    border-radius: 6px;
    font-size: 0.9rem;
    font-weight: bold;
    cursor: pointer;
    transition: background 0.2s ease;
  }
  
  .account-details button:hover {
    background: #004d99;
  }
  
  .account-details button:active {
    background: #003366;
  }
  
  /* Same styles as before, with minor updates for checkboxes */
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  ul li {
    display: flex;
    align-items: center;
    padding: 0.8rem 0; 
    border-bottom: 1px solid #ddd; 
  }
  
  ul li label {
    display: flex;
    align-items: center;
    gap: 0.5rem; 
    cursor: pointer;
    width: 100%; 
  }
  
  input[type="checkbox"] {
    width: 1.2rem; 
    height: 1.2rem;
    cursor: pointer;
  }
  
  ul li:hover {
    /* background-color: #f9f9f9;  */
    border-radius: 4px; 
  }
  
  p {
    margin-top: 1rem;
    color: #555;
    font-size: 0.9rem;
  }
  @media (max-width: 720px) {
    .dashboard-container {
      flex-direction: column;
    }
  
    .left-sidebar {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 60%;
      z-index: 5;
      transform: translateX(-100%);
      transition: transform 0.3s ease-in-out;
    }
  
    .right-content {
      flex: 1;
    }
  
    .toggle-button {
      display: block;
    }}  