.vendor-form-container {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .vendor-form-container h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 700;
    color: #333;
  }
  
  .vendor-form-container label {
    display: block;
    margin-bottom: 8px;
    font-weight: 600;
    color: #555;
  }
  
  .vendor-form-container input[type="text"],
  .vendor-form-container input[type="tel"],
  .vendor-form-container select {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .vendor-form-container input[type="checkbox"] {
    margin-right: 10px;
  }
  
  .vendor-form-container .checkbox-group {
    margin-bottom: 15px;
  }
  
  .vendor-form-container button[type="submit"] {
    width: 100%;
    padding: 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .vendor-form-container button[type="submit"]:hover {
    background-color: #0056b3;
  }
  
  .vendor-form-container .services {
    margin-bottom: 20px;
  }
  
  .vendor-form-container .services div {
    margin-bottom: 10px;
  }
  
  .vendor-form-container input:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
  
  .vendor-form-container select:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
  