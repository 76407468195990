.address-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1rem;
    font-family: 'Roboto Condensed', sans-serif;
    max-width: 100%;
  }
  
  /* Edit section and saved address container */
  /* .edit-section, */
  .saved-address {
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  }
  
  .edit-section:hover,
  .saved-address:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  /* Saved address header */
  .saved-address h4 {
    margin-bottom: 1rem;
    color: #333;
    font-size: 1.5rem;
    border-bottom: 2px solid #4caf50;
    padding-bottom: 0.5rem;
  }
  
  /* Buttons styles */
  .edit-btn,
  .delete-btn,
  .save-btn,
  .cancel-btn {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    width: 100%;
    max-width: 100px;
    text-align: center;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .delete-btn {
    background-color: #f44336;
  }
  
  /* Button hover effects */
  .edit-btn:hover,
  .save-btn:hover {
    background-color: #45a049;
    transform: scale(1.05);
  }
  
  .delete-btn:hover {
    background-color: #d32f2f;
    transform: scale(1.05);
  }
  
  .cancel-btn:hover {
    background-color: #ed0909;
    transform: scale(1.05);
  }
  
  /* Button group layout */
  .button-group {
    display: flex;
    gap: 1rem;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  
  /* Responsive adjustments */
  @media (max-width: 1024px) {
    .address-container {
      padding: 0.8rem;
    }
  
    .edit-section,
    .saved-address {
      padding: 0.8rem;
    }
  
    .saved-address h4 {
      font-size: 1.3rem;
    }
  
    .button-group {
      justify-content: space-between;
    }
  }
  
  @media (max-width: 768px) {
    .address-container {
      padding: 0.5rem;
    }
  
    .edit-section,
    .saved-address {
      padding: 0.5rem;
    }
  
    .saved-address h4 {
      font-size: 1.2rem;
    }
  
    .button-group {
      justify-content: center;
      gap: 0.5rem;
    }
  
    .edit-btn,
    .delete-btn,
    .save-btn,
    .cancel-btn {
      max-width: 100%;
    }
  }
  
  @media (max-width: 480px) {
    .saved-address h4 {
      font-size: 1.1rem;
    }
  
    .button-group {
      flex-direction: column;
      gap: 0.7rem;
      align-items: center;
    }
  
    .edit-btn,
    .delete-btn,
    .save-btn,
    .cancel-btn {
      max-width: 100%;
      width: 100%;
    }
  }  