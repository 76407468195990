.registration-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Ensures full height on all devices */
  padding: 1rem; /* Adds spacing on smaller devices */
  /* background: linear-gradient(135deg, #b0e69a, #abe680); */
  font-family: "Roboto", sans-serif;
}

.registration-card {
  background: #fff;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 380px;
  width: 100%; /* Ensures full width for small screens */
  box-sizing: border-box; /* Includes padding in width calculation */
}

h2 {
  margin-bottom: 1rem;
  color: #333;
  text-align: center;
  font-size: 1.5rem;
}

.form-group {
  margin-bottom: 1rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  font-size: 0.9rem;
}

input {
  width: 100%;
  padding: 0.6rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.9rem;
}

.submit-btn {
  width: 100%;
  padding: 0.6rem;
  background-color: #6b73ff;
  border: none;
  color: #fff;
  font-size: 0.9rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
  background-color: #5a62d6;
}

.toggle-text {
  text-align: center;
  margin-top: 1rem;
  color: #666;
  font-size: 0.85rem;
}

.toggle-text span {
  color: #6b73ff;
  cursor: pointer;
  font-weight: bold;
}

.error-messages {
  margin-top: 10px;
  padding: 10px;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  color: #ae1625;
}

.error-message {
  margin: 5px 0;
  font-size: 14px;
}

/* Enhanced Mobile Responsiveness */
@media (max-width: 480px) {
  .registration-container {
    padding: 0.5rem; /* Adds more breathing room */
  }

  .registration-card {
    padding: 1rem;
    max-width: 90%; /* Ensures card doesn't take up entire screen */
  }

  h2 {
    font-size: 1.25rem;
    margin-bottom: 0.8rem; /* Adjusts spacing for smaller screens */
  }

  input {
    font-size: 0.85rem;
    padding: 0.5rem; /* Reduces input padding */
  }

  .submit-btn {
    font-size: 0.85rem;
    padding: 0.5rem;
  }

  .toggle-text {
    font-size: 0.8rem;
  }

  .error-messages {
    font-size: 0.85rem;
    padding: 8px;
  }

  .error-message {
    font-size: 0.8rem;
  }
}