.vendor-services-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr); 
    gap: 0.8rem; 
    padding: 1rem;
  }
  
  .service-card {
    background: #ffffff;
    border-radius: 6px;
    padding: 0.6rem; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    transition: transform 0.2s, box-shadow 0.2s, background-color 0.3s ease-in-out;
    overflow: hidden;
    justify-content: space-between; 
    position: relative; 
  }
  
  .service-card:hover {
    transform: translateY(-4px); 
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  }
  
  .service-card h2 {
    font-size: 0.9rem; 
    color: #333;
    margin: 0.5rem 0;
    font-weight: 600;
    text-overflow: ellipsis;
    white-space: nowrap;
    /* overflow: hidden; */
  }
  
  .service-card p {
    font-size: 0.8rem; 
    color: #555;
    margin: 0.25rem 0;
  }

  .service-location {
    word-break: break-all;
  }
  
  .service-location h3 {
    font-size: 0.8rem;
    color: #333;
    margin-top: 1rem;
  }
  
  .service-location p {
    font-size: 0.7rem; 
    color: #777;
    word-wrap: break-word;
  }
  
  .service-card .price {
    font-size: 1rem; 
    font-weight: bold;
    color: #009688; 
  }
  
  .service-icons {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    gap: 8px;
  }
  
  .edit-icon, .delete-icon {
    cursor: pointer;
    font-size: 1.2rem;
    color: #007bff; /* Edit icon color */
  }
  
  .delete-icon {
    color: #e74c3c; /* Delete icon color */
  }
  
  /* .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: #ffffff;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    animation: slideIn 0.3s ease;
  }
  
  .modal-content h3 {
    margin-bottom: 15px;
  }
  
  .modal-input {
    width: 90%;
    padding: 10px;
    font-size: 16px;
    margin: 15px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .modal-actions {
    display: flex;
    justify-content: space-around;
  } */
  
  .save-button,
  .delete-button,
  .cancel-button {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .save-button {
    background-color: #4caf50;
    color: #fff;
  }
  
  .delete-button {
    background-color: #f44336;
    color: #fff;
  }
  
  .cancel-button {
    background-color: #9e9e9e;
    color: #fff;
  }
  
  .save-button:hover,
  .delete-button:hover,
  .cancel-button:hover {
    opacity: 0.9;
  }
  
  @keyframes slideIn {
    from {
      transform: translateY(-30px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  /* Responsive Adjustments */
  @media (max-width: 1024px) {
    .vendor-services-list {
      grid-template-columns: repeat(3, 1fr); /* 3 cards on tablets */
    }
  }
  
  @media (max-width: 768px) {
    .vendor-services-list {
      grid-template-columns: repeat(2, 1fr); /* 2 cards on smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .vendor-services-list {
      grid-template-columns: 1fr; /* 1 card per row on very small screens */
    }
  }
  