.main-service-details{
  margin: 0px 285px 85px 285px;
}
.main-service-title{
  margin-top: -2%;
}
.main-service-list{
  display: flex;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  gap: 2%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 35px;
}

.main-service-list > img {
  width: 40%;
  height: auto;
  border-radius: 10px;
}
.main-service-list > li{
  width: 40%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 0%;
}
.main-price{
  height: auto;  
  display: flex;
  flex-direction: column;
  align-items: center;
}
.price-with-offer{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: large;
}
.main-price > button{
  height: 40px;
  width: 100px;
  border-radius:4px;
  background-color: #FFFFFF;
  color: #2196F3;
  border-color: #2196F3;
  font-weight: bold;
  font-size: 20px;
  outline: none;
  box-shadow: none;
  margin-top: 20px;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.main-price > button:hover {
  border:1px solid #009587;
  color: #009587;
  background-color:#E6F5F3;
}

p{
  text-align: justify;
}

.fixed-top-right {
  position: fixed;
  top: 127px;
  right: 280px;
  z-index: 1050;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cart-item-count {
  top: -10px;
  right: -10px;
  padding: 3px 6px;
  border-radius: 50%;
  background-color: red;
  color: white;
  font-size: 0.8em;
}
